.detail-container {
  width: 100%;
  min-height: fit-content;
  display: flex;
}

.left-box {
  width: 67%;
  min-height: 200px;
}

.right-box {
  width: 30%;
  min-height: 200px;
}

.status-alert-section {
  width: 87.5%;
  margin-left: 5%;
  margin-bottom: 20px;
}

.participants {
  width: 100%;
  display: flex;
}

.user-box {
  width: 50%;
  float: left;
}

.player-box {
  width: 50%;
  float: right;
}

.detail-info-box {
  width: 87.5%;
  margin-top: 20px;
  margin-left: 5%;
}

.highlight-number {
  color: #f5222d;
}
