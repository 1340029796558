.payoff-page {
  width: 100%;
  height: 100%;
  background-color: whitesmoke;
  font-size: medium;
  padding: 20px;
  overflow: scroll;
}

.payoff-header {
  width: 100%;
  text-align: center;
}

.part1 {
  height: 100%;
  width: 60%;
}

.part1-des {
  padding: 10px;
}
