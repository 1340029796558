.gift-main {
    height: 90%;
    background-color: white;
}

.gift-header {
    height: 50px;
    text-align: center;
    font-size: 30px;
    background-color: white;
}

.gift-content {
    height: 100%;
    width: 100%;
    padding-top: 20px;
    padding-left: 20px;
    background-color: white;
}