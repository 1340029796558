.achievement-body {
  width: 100%;
  height: 100%;
  font-weight: 500;
  overflow: scroll;
  font-family: Arial, Helvetica, sans-serif;
}

.number {
  color: red;
}

.part1 {
  width: 50%;
  margin-left: 25%;
  padding-left: 20px;
  padding-top: 20px;
}

.part2 {
  width: 50%;
  margin-left: 25%;
  padding-left: 20px;
}

.part3 {
  width: 50%;
  margin-left: 25%;
  padding-left: 20px;
}

.part1_body {
  font-size: medium;
}

.part3_body {
  font-size: medium;
}

.part2_comments {
  padding: 10px;
}

.spec-style1 {
  color: grey;
  font-family: 'Cursive';
  font-weight: bold;
}
