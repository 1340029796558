.report-body {
    height: 95%;
    background-color: white;
}

.report-header {
    height: 50px;
    text-align: center;
    font-size: 30px;
    background-color: white;
}

.report-form-body {
    width: 100%;
    height: 100%;
    padding-top: 20px;
    padding-left: 20px;
    background-color: white;
}