.action-btn {
  border-radius: 5px;
  border: 0.1px solid grey;
  font-weight: 500;
}

.action-btn:hover {
  background-color: #f5222d;
  color: #f6f6f6;
  border: 0.1px solid #f5222d;
}

.action-btn-danger {
  color: #f6f6f6;
  background-color: #ff4d4f;
  border-radius: 5px;
  border: 0.1px solid grey;
  font-weight: 500;
}

.action-btn-danger:hover {
  background-color: #f5222d;
  color: #f6f6f6;
  border: 0.1px solid #f5222d;
}
