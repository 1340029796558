.login {
  /* 100%窗口高度 */
  height: 100vh;
  width: 100vw;
  /* 弹性布局 水平+垂直居中 */
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../images/wallpaper_proc.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  /* 渐变背景 */
  /*background: linear-gradient(200deg,#ffcccc,#e3eeff);*/
}

.container {
  width: 33%;
  height: 60%;
  text-align: center;
  padding: 20px;
  background-color: white;
  border-radius: 2em;
}

.container button {
  width: 60%;
  height: auto;
  margin-top: 35px;
  font-weight: 700;
  font-size: 1.5em;
  border-radius: 10px;
  border: 0.5px solid;
  padding: 10px;
  background-color: #f6f6f6;
  color: grey;
  letter-spacing: 0.2em;
  cursor: pointer;
}

.container button:hover {
  background-color: #f5222d;
  color: #f6f6f6;
  transition: background-color 0.5s ease;
}

/* PlayerLogin css */
.player-customer-container {
  width: 400px;
  height: 450px;
  background-color: white;
  text-align: center;
  border-radius: 25px;
  text-align: center;
  padding: 5px 40px;
  box-sizing: border-box;
  /* 这样padding就不会影响大小 */
}

.player-customer-container h1 {
  margin-top: 50px;
  margin-bottom: 40px;
}

.player-customer-container .submit-button {
  background-color: #ff4d4f;
  width: 60%;
  height: 44px;
  border-radius: 10px;
  border: 0.5px solid;
  font-size: 1.5em;
  font-weight: 600;
  letter-spacing: 0.2em;
}

.player-customer-container .submit-button:hover {
  background-color: #f5222d;
  color: #f6f6f6;
  transition: background-color 0.4s ease;
}

.player-customer-container .dc-btn {
  background-color: #5865f2;
  width: 60%;
  height: 44px;
  border-radius: 10px;
  border: 0.5px solid;
  font-size: 1.5em;
  font-weight: 600;
  letter-spacing: 0.2em;
}

.player-customer-container .dc-btn:hover {
  background-color: #2f54eb;
  color: #ffffff;
  transition: background-color 0.4s ease;
}
