.player-user-page {
  /* 100vh & vw窗口高度 */
  height: 100vh;
  width: 100vw;
  font-weight: 450;
}

.player-user-page h2 {
  font-size: 1.5em;
  font-weight: 600;
}

.player-user-body {
  width: 100vw;
  margin-top: 13px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.header {
  width: 100vw;
  height: 61px;
  background: linear-gradient(200deg, #69c0ff, #bae7ff); /* 渐变背景 */
}

.header .logo {
  float: left;
  display: flex;
  height: 61px;
  line-height: 61px;
  margin-left: 35px;
  font-size: large;
  font-weight: 600;
  letter-spacing: 0.2em;
}

.header .account {
  float: right;
  height: 61px;
  line-height: 61px;
  margin-left: 35px;
  font-size: 1.5em;
  font-weight: 600;
  margin-right: 35px;
}

.site-layout-background {
  background: #fff;
  overflow-y: scroll;
}
