.form-main {
    height: 100%;
    background-color: white;
    overflow-y: auto;
}

.form-header {
    height: 50px;
    text-align: center;
    font-size: 30px;
    background-color: white;
}

.form-content {
    height: 100%;
    padding-top: 20px;
}

.form-content-left {
    width: 100%;
    height: 100%;
    padding-top: 20px;
    padding-left: 20px;
    background-color: white;
}

.form-result {
    width: 100%;
    height: 100px;
    text-align: center;
}